import React, { Component } from 'react';
import ReactTimeAgo from 'react-time-ago'

export class Rankings extends Component {
  static displayName = Rankings.name;

  constructor(props) {
    super(props);
    this.populateRecords = this.populateRecords.bind(this);
    this.state = { stats: [], loading: true };
  }
  static getWinLoss(result) {
    if (result === 1){return "W";}
    if (result === 0){return "D";}
    if (result === -1){return "L";}
  }
  static renderRecords(records) {
    return (
      <table className='table table-striped'>
            <thead>
                <tr>
                    <th></th>
                    {records.map(rec => 
                      <th key={rec.player.id}><img src={rec.player.imageUrl} width="30" alt="" /></th>
                      )} 
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Current rank</td>
                        {records.map(rec => 
                          <td key={rec.player.id}><img src={rec.rankImage} alt="" width="30" /></td>
                      )} 
                    </tr>
                    <tr>
                        <td>Last change</td>
                        {records.map(rec => 
                          <td key={rec.player.id}>
                            <div className={rec.lastChangeWasRankUp === true ? "plus" : "minus"}>
                            {rec.lastChangeWasRankUp ? "Up" : "Down"}
                            </div>
                            <ReactTimeAgo date={rec.dateForLastChange} locale="en-US"/>
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Games played</td>
                        {records.map(rec => 
                          <td key={rec.player.id}>
                            {rec.gamesPlayedSinceRank}
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Win/loss</td>
                        {records.map(rec => 
                          <td key={rec.player.id} className={rec.winsLossSinceRank >= 0 ? "plus" : "minus"}>                            
                            {rec.winsLossSinceRank }
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Rounds won/lost</td>
                        {records.map(rec => 
                          <td key={rec.player.id} className={rec.roundWinsLossSinceRank >= 0 ? "plus" : "minus"}>                            
                            {rec.roundWinsLossSinceRank }
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Kill/Death</td>
                        {records.map(rec => 
                          <td key={rec.player.id} className={rec.killsSinceRank >= rec.deathsSinceRank ? "plus" : "minus"}>                            
                            {rec.killsSinceRank - rec.deathsSinceRank }
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Rating</td>
                        {records.map(rec => 
                          <td key={rec.player.id} className={rec.ratingSinceRank >= 1 ? "plus" : "minus"}>                            
                            {rec.ratingSinceRank }
                          </td>
                      )}                       
                    </tr>
                    <tr>
                        <td>Lastest matches</td>
                        {records.map(rec => 
                          <td key={rec.player.id} className="result">                            
                            {rec.last5Games?.map(pos =>
                              <span className={pos<0 ? "minus":"plus"}>{Rankings.getWinLoss(pos)}</span>
                              ) }
                          </td>
                      )}                       
                    </tr>
                </tbody>
            </table>         
    );
  }
  componentDidMount(){
    this.populateRecords();
  }
  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Rankings.renderRecords(this.state.records);

    return (
      <div>
        <h1 id="tabelLabel">Rank information</h1>
        <p>Accumulated figures since last rank/derank </p>
        {contents}
      </div>
    );
  }

  async populateRecords() {
    const response = await fetch('stats/rankings');
    const data = await response.json();
    this.setState({ records: data, loading: false });
  }
}
