import React, { Component } from 'react';
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = { activePicker : '' };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(dateObj, activePicker) {
        // let currentUrlParams = new URLSearchParams(window.location.search);
        // currentUrlParams.set('time', 'A');
        // useHistory().push(window.location.pathname + "?" + currentUrlParams.toString());
        this.props.onDateChange(dateObj);
        this.setState({activePicker: activePicker});
      }
    componentDidMount() {
        var activePicker = this.props.activePicker || this.getInitialPicker();
        this.pickerChanged(activePicker);
    }
    getInitialPicker(){
        return '3'
        //const d = new Date().getDate();
        //return d < 15 ? '4' : '5'; 
    }
    pickerChanged(activePicker){
        switch(activePicker){
            case '2':
                this.setXMonthsTime(6,activePicker)
                break;
            case '3':
                this.setXMonthsTime(3,activePicker)
                break;
            case '4':
                this.setXMonthsTime(1,activePicker)
                break;
            case '5':
                this.setCurrentMonth(activePicker)
                break;
            default:
                this.setAllTime();
                break;
        }
    }
    setAllTime() {        
        this.handleChange({ dateFrom: '2000-01-01', dateTo:'2100-01-01'}, '1');       
    }
    setXMonthsTime(months, activePicker) {
        const d = new Date();
        const month = d.getMonth();
        d.setMonth(month - months);
        while (d.getMonth() === month) {
            d.setDate(d.getDate() - 1);
        }
        this.handleChange({ dateFrom: d.toISOString(), dateTo:'2100-01-01'},activePicker);
    }
    setCurrentMonth(activePicker) {
        const d = new Date();
        d.setDate(1);
        this.handleChange({ dateFrom: d.toISOString(), dateTo:'2100-01-01'},activePicker);
    }
    getDateFrom() {
        return this.state.dateFrom;
    }
    getDateTo() {
        return this.state.dateTo;
    }
    render () {
        return (            
        <div className='date-picker'>
            <select id="date-picker" value={this.state.activePicker} onChange={(e) => this.pickerChanged(e.target.value)}>
                <option value="1">All Time</option>
                <option value="2">Last 6 months</option>
                <option value="3">Last 3 months</option>
                <option value="4">Last month</option>
                <option value="5">{`${monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`}</option>
            </select>
        </div>);
    }
}