import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import {PLAYER_CONSTANTS} from './PlayerConstants'
import {DatePicker} from './DatePicker'
export class Home extends Component {
  static displayName = Home.name;
  constructor(props) {
    super(props);
    this.populateRatingRecords = this.populateRatingRecords.bind(this);
    this.setAccumulated = this.setAccumulated.bind(this);
    this.state = { accumulate : true, data: [], activeData:{}, loading: true, dateFrom:'', dateTo:'' };
  }
  setChart(chart) {
    this.setState({
      activeData:chart
    });
  }
  
  static options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          display: true,
          ticks: {
          }
      }]
    },
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  if(context.dataset.dataCount){
                    label = `${context.dataset.label}: ${Math.round((context.parsed.y + Number.EPSILON) * 1000) / 1000} / ${context.dataset.dataCount[context.dataIndex]} games`;
                  }else{
                    label = `${context.dataset.label}: ${Math.round((context.parsed.y + Number.EPSILON) * 1000) / 1000}`;                      
                  }
                }
                return label;
              }
          }
      }
    }
  }; 
  renderChartSelector(data){
    return (
      <div className='chart-data-selector'>
        {data.map(chart =>    
        <button key={chart.title} onClick={() => this.setChart(chart)}>{chart.title}</button>  
        )}  
        <input type="checkbox" name="accu-selector" checked={this.state.accumulate} onChange={this.setAccumulated} />
        <label htmlFor="accu-selector">Accumulate scores</label>
      </div>
    );
  }
  static renderChart(data, options) {
    data.chartData.datasets.forEach(element => {
      element.fill = false;
      var playerOptions = PLAYER_CONSTANTS[element.label.toLowerCase()];
      element.backgroundColor = playerOptions.lineColor;
      element.borderColor = playerOptions.borderColor;
      element.tension = 0.25;  
      element.cubicInterpolationMode = 'monotone'
    });
    
    return (
      <>
      <h1 id="tabelLabel">{data.title}</h1>
      <small>{data.subTitle}</small>        
      <div className="chart-container">
        <Line data={data.chartData} options={options} />
      </div>
      </>
    );
  }
  static renderGamesChart(data, options) {
    var newData = { datasets:[], labels:data.labels};
    data.datasets.forEach(element => {
      var clonedElement = {};
      clonedElement.fill = element.fill;      
      clonedElement.backgroundColor = element.backgroundColor;
      clonedElement.borderColor = element.borderColor;
      clonedElement.tension = element.tension;
      clonedElement.label = element.label;
      clonedElement.data = element.dataCount;
      newData.datasets.push(clonedElement);
    });
    return (
      <div className="chart-container">
        <Line data={newData} options={options} />
      </div>              
    );
  }
  render () {
    let selector = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderChartSelector(this.state.data);
    let ratingContents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Home.renderChart(this.state.activeData, Home.options);
    let gamesContents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Home.renderGamesChart(this.state.activeData.chartData, Home.options);
    return (
      <div>
        <DatePicker onDateChange={this.populateRatingRecords} />
        {selector}
        {ratingContents}
        <br/>
        <h1 id="tabelLabel3">Games played</h1>
        {gamesContents}
      </div>
    );
  }
  setAccumulated(){
    var newValue = !this.state.accumulate;
    this.setState({accumulate : newValue});
    this.populateRatingRecords({dateFrom: this.state.dateFrom, dateTo: this.state.dateTo}, newValue);
  }
  async populateRatingRecords(dateObj, accumulate) {
    if(!accumulate && accumulate !== false){accumulate = this.state.accumulate};
    const response = await fetch(`stats/history/from/${dateObj.dateFrom}/to/${dateObj.dateTo}?accumulate=${accumulate}`);    
    const data = await response.json(); 
    var activeData = data[0];
    if(this.state.activeData?.title){
      activeData = data.find((element) => {
        return element.title === this.state.activeData?.title;
      })
    }   
    this.setState({ data : data, loading: false, activeData: activeData, dateFrom: dateObj.dateFrom, dateTo: dateObj.dateTo });
  }
}
