import React, { Component } from 'react';
import {DatePicker} from './DatePicker'

export class Totals extends Component {
  static displayName = Totals.name;

  constructor(props) {
    super(props);
    this.populateRecords = this.populateRecords.bind(this);
    this.state = { stats: [], loading: true };
  }

  static renderRecords(records) {
    return (
        <>        
        { records.map(rec =>
        <div className="table-totals" key={rec.id}>
            <h2 id={"rec_" + rec.id}>{rec.recordName}</h2>
            <table className='table table-striped' aria-labelledby={"rec_" + rec.id}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Player</th>                    
                    { !rec.isCountOnly &&
                      <th>Average</th>
                    } 
                    { !rec.isScoreOnly &&
                      <th>Total</th>
                    } 
                </tr>
                </thead>
                <tbody>
                { rec.topPlayers.map(playerScore =>
                    <tr key={playerScore.id}>
                        <td>{playerScore.id + 1}</td>
                        <td><img src={playerScore.player.imageUrl} alt={playerScore.player.name} width="45px" /></td>                        
                        { !rec.isCountOnly &&
                          <td>{playerScore.score}</td>
                        } 
                        { !rec.isScoreOnly &&
                          <td>{playerScore.total}</td>
                        }        
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        )}
        </>      
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Totals.renderRecords(this.state.records);

    return (
      <div>
        <DatePicker onDateChange={this.populateRecords} />
        <h1 id="tabelLabel">Totals</h1>
        <p>Accumulated figures </p>
        {contents}
      </div>
    );
  }

  async populateRecords(dateObj) {
    const response = await fetch(`stats/top/totals/from/${dateObj.dateFrom}/to/${dateObj.dateTo}`);
    const data = await response.json();
    this.setState({ records: data, loading: false });
  }
}
