import React, { Component } from 'react';
import ReactTimeAgo from 'react-time-ago'
import {DatePicker} from './DatePicker'

export class TopRecords extends Component {
  static displayName = TopRecords.name;

  constructor(props) {
    super(props);
    this.populateRecords = this.populateRecords.bind(this);
    this.state = { stats: [], loading: true };
  }

  static renderRecords(records) {
    return (
        <>
        { records.map(rec =>
        <div className="table-records" key={rec.id}>
            <h2 id={"rec_" + rec.id}>{rec.recordName}</h2>
            <table className='table table-striped' aria-labelledby={"rec_" + rec.id}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Player</th>
                    <th>Score</th>
                    <th>Date</th>
                    <th>Map</th>
                    <th>Link</th>
                </tr>
                </thead>
                <tbody>
                { rec.topPlayers.map(playerScore =>
                    <tr key={playerScore.id}>
                        <td>{playerScore.id + 1}</td>
                        <td><img src={playerScore.player.imageUrl} alt={playerScore.player.name} width="45px" /></td>
                        <td>{playerScore.score}</td>
                        <td><ReactTimeAgo date={playerScore.date} locale="en-US"/></td>
                        <td>{playerScore.map || "N/A"}</td>
                        <td>
                            {playerScore.matchUrl 
                            ? <a target="_blank" rel="noopener noreferrer" to="https://csgostats.gg" href={playerScore.matchUrl}>Match details</a>
                            : "N/A"}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        )}
        </>      
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : TopRecords.renderRecords(this.state.records);

    return (
      <div>
        <DatePicker onDateChange={this.populateRecords} />
        <h1 id="tabelLabel">FCW all time highs</h1>
        <p>High/low scores for a single match. Tables with a * in the heading means only complete matches included </p>
        {contents}
      </div>
    );
  }

  async populateRecords(dateObj) {
    const response = await fetch(`stats/top/from/${dateObj.dateFrom}/to/${dateObj.dateTo}`);
    const data = await response.json();
    this.setState({ records: data, loading: false });
  }
}
