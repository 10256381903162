export const PLAYER_CONSTANTS = {
    "blue" : {
        lineColor:"rgb(0, 0, 255, 0.4)",
        borderColor:"rgba(0, 0, 255)"
    },
    "havefars" : {
        lineColor:"rgb(204, 204, 0, 0.4)",
        borderColor:"rgba(204, 204, 0)"
    },
    "warbugii" : {
        lineColor:"rgb(102, 51, 0, 0.4)",
        borderColor:"rgba(102, 51, 0)"
    },
    "herqles" : {
        lineColor:"rgb(38, 153, 0, 0.4)",
        borderColor:"rgba(38, 153, 0)"
    },
    "thufir" : {
        lineColor:"rgb(204, 0, 153, 0.4)",
        borderColor:"rgba(204, 0, 153)"
    },
    "mythor" : {
        lineColor:"rgb(0, 0, 0, 0.4)",
        borderColor:"rgba(0, 0, 0)"
    },
    "xar" : {
        lineColor:"rgb(255, 0, 0, 0.4)",
        borderColor:"rgba(255, 0, 0)"
    },
    "hjælpedoctor" : {
        lineColor:"rgb(255, 153, 0, 0.4)",
        borderColor:"rgba(255, 153, 0)"
    },
    "la-chris" : {
        lineColor:"rgb(114, 114, 114, 0.4)",
        borderColor:"rgba(114, 114, 114)"
    }
}