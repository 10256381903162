import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './custom.css'
import { TopRecords } from './components/TopRecords';
import { PlayerTopRecords } from './components/PlayerTopRecords';
import { Rankings } from './components/Rankings';
import { Totals } from './components/Totals';
import { Home } from './components/Home';

export default class App extends Component {
  static displayName = App.name;  
  render () {
    TimeAgo.addDefaultLocale(en)
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/totals' component={Totals}  />
        <Route path='/match-records' component={TopRecords} />
        <Route path='/player-records' component={PlayerTopRecords} />
        <Route path='/rankings' component={Rankings} />
      </Layout>
    );
  }
}
